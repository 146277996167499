import Penguin1 from "../images/timeline1.png"
import Penguin2 from "../images/timeline2.png"
import Penguin3 from "../images/timeline3.png"
import Penguin4 from "../images/timeline4.png"
import Penguin5 from "../images/timeline5.png"

export const STORGE_TIMELINE = [
  {
    header: "vytvoření struktury<br>v redakčním systému",
    text: "včetně obsahu",
    image: Penguin1,
    upperCaseHeader: true,
  },
  {
    header: "nachystání struktury šablon",
    text: "včetně dat z redakčního systému",
    image: Penguin2,
    upperCaseHeader: true,
  },
  {
    header: "doplnění nového designu",
    text: "dodaného klientem",
    image: Penguin3,
    upperCaseHeader: true,
  },
  {
    header: "vytvoření registračních<br>a objednávkových formulářů",
    text: "a jejich následné otestování",
    image: Penguin4,
    upperCaseHeader: true,
  },
  {
    header: "spuštění nového webu",
    text: "v domluveném termínu",
    image: Penguin5,
    upperCaseHeader: true,
  },
]
