import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import Logo from "../../../images/reference/storge/logo-storge.png"
import OgImage from "../../../images/reference/storge/og-image.png"
import Screen from "../../../images/reference/storge/storge-uvodni.png"
import Screen1 from "../../../images/reference/storge/storge1.png"
import Screen2 from "../../../images/reference/storge/storge2.png"
import Sobol from "../../../images/team/mini/sobol.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import {
  Content,
  H2,
  Infobox,
  Medailon,
  PaddingWrapper,
  Timeline,
} from "@igloonet-web/shared-ui"
import { Container } from "reactstrap"
import { STORGE_TIMELINE } from "../../../model/storge-reference-timeline"
import TwoImagesNextToEachOther from "../../../components/reference-detail/two-images-to-next-each-other"

const Storge: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Webová prezentace velkoobchodu květin Storge | igloonet.cz
        </title>
        <meta
          name="description"
          content="Přechod na aktualizovaný redakční systém i expanze na Slovensko."
        />
        <meta
          property="og:title"
          content="Webová prezentace velkoobchodu květin Storge | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <ReferenceHeader
        companyName="storge"
        heading="Přechod na aktualizovaný redakční systém i&nbsp;expanze na Slovensko"
        date="pohled červen 2022 – srpen 2022"
      />

      <ReferenceImageText
        right
        img={Screen}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo společnosti Storge"
        link="//storge.cz/"
      >
        <p>
          Pro společnost{" "}
          <a href="//storge.cz/" target="_blank" rel="noreferrer">
            Storge s.r.o.{" "}
          </a>
          jsme v&nbsp;roce 2015 vytvořili webovou prezentaci v&nbsp;redakčním
          systému Drupal&nbsp;7. Umožňovala registraci nových zákazníků,
          objednávky řezaných květin a&nbsp;informovala o&nbsp;otevírací době
          poboček v&nbsp;Brně a&nbsp;v&nbsp;Praze.
        </p>
        <p>
          Dalším logickým krokem byla pro klienta expanze na Slovensko. Proto
          potřeboval nový web i&nbsp;design. Postavili jsme ho
          v&nbsp;aktualizovaném redakčním systému Drupal&nbsp;9 a&nbsp;vytvořili
          dva nezávislé weby, jeden v&nbsp;CZ a&nbsp;druhý v&nbsp;SK verzi.
          Použili jsme přitom jednu šablonu s&nbsp;cílem ušetřit maximum
          nákladů. Přečtěte si, co vše jsme se Storge zvládli.
        </p>
      </ReferenceImageText>

      <ReferenceImageText>
        <h2>Storge</h2>
        <p>
          Historie Storge sahá až do roku 1999, kdy byla založena jako
          Velkoobchod květin Ján Balko sídlící v&nbsp;Brně. V&nbsp;roce 2008 se
          firma transformovala a&nbsp;přijala název Storge, což je starořecké
          označení pro náklonnost. Díky široké nabídce sortimentu a&nbsp;nízkým
          cenám si společnost v&nbsp;průběhu let vybudovala silnou
          a&nbsp;stabilní pozici na českém trhu.
        </p>
        <p>
          Storge klade důraz na kvalitu a&nbsp;čerstvost nabízeného zboží, proto
          nakupuje velkou část sortimentu přímo od pěstitelů nebo na Holandské
          květinové burze. V&nbsp;roce 2014 otevřeli pobočku v&nbsp;Praze
          a&nbsp;v&nbsp;roce 2022 nový velkoobchod v&nbsp;Bratislavě.
        </p>
      </ReferenceImageText>

      <ReferenceImageText>
        <Content noPadding>
          <H2>Co klient spoluprací získal</H2>
          <ol>
            <li>
              <p>
                Dva nezávislé weby&nbsp;– jeden pro Česko a&nbsp;druhý pro
                Slovensko. Použití stejné šablony přineslo významnou úsporu
                nákladů.
              </p>
            </li>

            <li>
              <p>
                Jednoduše spravovatelný systém přímo přes{" "}
                <a
                  href="//drupal.org/docs/develop/using-composer/manage-dependencies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Composer{" "}
                </a>
                a&nbsp;
                <a href="//drush.org/12.x/" target="_blank" rel="noreferrer">
                  Drush{" "}
                </a>
                na serveru, díky čemuž došlo ke snížení nákladů na jeho správu.
              </p>
            </li>
            <li>
              <p>
                Možnost následného snadného přechodu z&nbsp;Drupal&nbsp;9 na
                Drupal&nbsp;10.
              </p>
            </li>
          </ol>
        </Content>
      </ReferenceImageText>

      <PaddingWrapper>
        <Container fluid className="text-center pt-5 mt-2">
          <Timeline data={STORGE_TIMELINE} />
        </Container>
      </PaddingWrapper>

      <ReferenceImageText>
        <p>
          Při tvorbě webové prezentace začínáme standardně výzkumnou částí
          a&nbsp;až poté tvoříme návrhy. Klienta ale v&nbsp;tomto případě tlačil
          čas a&nbsp;dodal již hotovou grafiku, proto jsme tento běžný postup
          vynechali a&nbsp;pustili se rovnou do tvorby webu. Než aktualizovat
          stávající redakční systém Drupal&nbsp;7, bylo v&nbsp;tomto případě
          vhodnější vystavět nový web na aktuální a&nbsp;zabezpečené verzi
          redakčního systému Drupal&nbsp;9.
        </p>
      </ReferenceImageText>

      <PaddingWrapper noPaddingBottom>
        <Medailon
          personName="Jan Nobilis"
          position="Vedoucí vývoje a jednatel"
          img={Sobol}
          className="mt-5"
        >
          <p>
            V&nbsp;projektu jsme spojili aktualizaci redakčního systému
            s&nbsp;požadavky klienta a&nbsp;s&nbsp;novým grafickým designem.
            Naším úkolem bylo web v&nbsp;co nejkratším termínu realizovat, což
            se ku spokojenosti všech podařilo.
          </p>
        </Medailon>
      </PaddingWrapper>

      <PaddingWrapper>
        <TwoImagesNextToEachOther
          inRowsOnPhones
          img1={Screen1}
          img2={Screen2}
          width1={540}
          width2={540}
          height1={641}
          height2={698}
        />
      </PaddingWrapper>

      <PaddingWrapper noPaddingBottom>
        <Infobox
          className="pb-0"
          heading="Proč řešit aktualizace redakčního systému?"
          text="Neaktuální redakční systém nebo některý z&nbsp;jeho modulů mohou způsobit
        vážné problémy. V&nbsp;zastaralých aplikacích již nikdo neopraví nově
        nalezené chyby a&nbsp;bezpečnostní zranitelnosti. Je tedy otázkou času, kdy
        je někdo napadne."
        />
        <Infobox
          className="pt-0"
          text=" Nejčastějšími hrozbami jsou krádeže dat, napadení návštěvníků webu či
        přímo změny stránek. Může dojít také ke zneužití vaší prezentace ke
        spamování nebo přímo k&nbsp;útokům na další weby. Je v&nbsp;podstatě jedno, jaký
        redakční systém využíváte. Jestli je to Drupal, Wordpress, Joomla nebo webová 
        prezentace postavená na míru, používání neaktuálních verzí aplikací vede často
        k&nbsp;jejich zneužití."
          img
        />
      </PaddingWrapper>

      <ReferenceImageText>
        <h2>Přechod z&nbsp;Drupal&nbsp;7 na Drupal&nbsp;9</h2>
        <p>
          Přechod na nový redakční systém se dotkl primárně vývojářů. Kompletně
          se změnil šablonovací systém a&nbsp;jádro Drupalu. Struktura
          a&nbsp;základní logika redakčního systému zůstala víceméně stejná,
          takže nebyl důvod se obávat příliš odlišné správy obsahu. Více
          o&nbsp;přechodu na novou verzi Drupal&nbsp;9 naleznete v&nbsp;
          <a
            href="//igloonet.cz/blog/drupal-9-mame-se-bat/"
            target="_blank"
            rel="noreferrer"
          >
            našem článku{" "}
          </a>
          nebo{" "}
          <a
            href="//linkedin.com/pulse/aktualizace-redak%C4%8Dn%C3%ADho-syst%C3%A9mu-drupal-b%C4%9Bh-na-kr%C3%A1tkou-nebo-jan-nobilis/"
            target="_blank"
            rel="noreferrer"
          >
            zde
          </a>
          .
        </p>
      </ReferenceImageText>

      <PaddingWrapper noPaddingBottom>
        <Medailon
          personName="Jan Nobilis"
          position="Vedoucí vývoje a jednatel"
          img={Sobol}
        >
          <p>
            Aktualizaci redakčního systému Drupal z&nbsp;verze&nbsp;7 lze
            provést pomocí modulů, které vám s&nbsp;tímto přechodem pomohou.
            Avšak pokud máte webové stránky s&nbsp;rozšířenými funkcemi mimo
            výchozí prostředí, nezbyde vám, než tyto funkce kompletně přepsat.
            Mezi verzemi&nbsp;7 a&nbsp;8 došlo k&nbsp;technologickému převratu,
            kdy Drupal přešel z&nbsp;vlastního frameworku na Symphony, což
            přináší mnoho výhod, jako například otevření většímu počtu vývojářů,
            univerzálnost, přenositelnost způsobu práce a&nbsp;rozšíření
            možností testování.
          </p>
        </Medailon>
      </PaddingWrapper>

      <ContactForm contact="sobol" />
    </Layout>
  )
}

export default Storge
